import React, {useState, useEffect} from 'react'
import { ThemeProvider } from 'styled-components'
import Header from '../components/Header';
import Game from './Game';
import theme from '../style/theme'


const Page:React.FC = () => {

    const [loading, setLoading] = useState(true);
    const [games] = useState([
        {
            name:"Money Makers",
            subtitle:"Became a millionaire in survival IDLE game!",
            tags:["idle", "stock", "incremental", "business", "simulator"],
            desc:"Start with simple jobs, gaining experience and knowledge to advance. While idling, increase your funds over time by making investments and taking advantage of the stock exchange. Build your own office with staff to maximize work profits. Finally, compete with your friends, gaining prestige and buying luxury goods. But remember – limited energy and resources (like in real life) make that only a few can survive and become millionaires! Are you one of them?",
            trialer:"https://www.youtube.com/watch?v=rVAFSDOimWc",
            android:"https://play.google.com/store/apps/details?id=air.funga.MoneyMakers"
        },
        {
            name:"Tap Tap West",
            subtitle:"Build your town in wild west.",
            tags:["idle", "western", "war", "idle", "mining"],
            desc:"Harvest resources and trade them. Build and upgrade your village. Recruit an army and then attack other players to loot their resources to continually increase your XP and fame!",
            trialer:"https://www.youtube.com/watch?v=39SNxz5mz0c",
            android:"https://play.google.com/store/apps/details?id=air.com.funga.TapTapWest"
        }
    ]);
    
    useEffect(() => {
        const timer = setTimeout(() => {
          console.log('This will run after 1 second!')
          setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
      }, []
    );

    return (
        <ThemeProvider theme={theme}>
            <Header loading = {loading}/>
            {games.map((game, i:number) => <Game key = {i} data = {game} id = {i}/>)}
        </ThemeProvider>
    )
}

export default Page;