import React from 'react'
import logo from '../assets/logo_alpha.png'
import Box from './Box'
import { transform } from '@babel/core';



interface Props {
    loading: boolean
}


const Header:React.FC<Props> = ({loading}) => {

    //const boxProps = {color:'black', bg:"blue", p:2}
    return (
        <div style = {{
            position:'sticky',
            top: 0,
            width:'100%',
            height: loading ? '100vh' : '6rem', 
            display:'flex', 
            background: 'radial-gradient(#61006a, #240043)',
            flexDirection: 'column', 
            justifyContent:'center' ,
            transition: 'height 0.3s ease-out',
            zIndex:999
        }}>
            <div style ={{
                padding:'1rem', 
                maxHeight: '20rem',
                height:'100%'
            }}>
            <img src = {logo} style = {{
                position:'relative',
                top: '50%',
                left: '50%',
                maxWidth: '80%',
                maxHeight: '60%',
                transform: 'translate(-50%, -50%)',
                height: 'auto'
            }}/>
            {/*
                <div style = {{
                    backgroundImage: `url(${logo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    height:'100%'
                }}/>
            */}
                
                {/*<Box 
                    {...boxProps}
                    width = {[
                        1, // 100% below the smallest breakpoint (all viewports)
                        1 / 2, // 50% from the next breakpoint and up
                        1 / 4, // 25% from the next breakpoint and up
                    ]}
                >
                    Tomato
                    </Box>*/}
            </div>
        </div>
    )
}

export default Header;