import * as React from 'react';
import ReactPlayer from 'react-player/youtube'
import Box from '../components/Box'
import gps_badge from '../assets/google-play-badge.png'

interface Props {
    id:number,
    data:Data
}

interface Data {
    name:string,
    subtitle:string,
    tags:string[],
    desc:string,
    android:string,
    trialer:string
}

const Game:React.FC<Props> = ({id, data}) => {
  
    const isEven:boolean = (id % 2 === 0) 
    const bgs:string[] = ['#fff', '#eee']
    
    return (
        <Box id = 'container'
            backgroundColor= {bgs[id%2]}
            //minHeight = 'calc(100vh - 6rem)'
            >
            <Box id = 'game section' 
                display = 'flex'
                flexDirection = {['column', 'row-reverse']}>
                
                <Box id = 'info'
                    overflowX = 'hidden'
                    padding = '1rem' 
                    width = {['calc(100%-2rem)', '40%']}
                    maxWidth = {['100%', '40%']}>
                    <h1 style = {{marginBottom:'.2rem'}}>{data['name']}</h1>
                    
                    <Box id = 'tags'
                        display = 'flex'
                        flexDirection = 'row'
                        flexWrap = 'wrap'
                        color = '#00518f'>
                        {data.tags.map((tag, i) => 
                            <Box 
                                key = {i}
                                padding = '.3rem' 
                                marginRight = '.2rem' 
                                marginBottom = '.2rem'
                                bg = {bgs[(id+1)%2]}>
                                {`${tag.toUpperCase()}`}
                            </Box>
                        )}
                    </Box>
                    <h3>{data['subtitle']}</h3>
                    <p>{data['desc']}</p>
                    
                    
                    <a href = {data['android']} target="_blank">
                        <img src = {gps_badge} style={{maxWidth:'200px'}}/>
                    </a>
                </Box>
                <Box id = 'media' 
                    paddingTop = {['56.25%', '35%']} 
                    paddingBottom = {[0, '1rem']} 
                    paddingLeft = {[0, '1rem']} 
                    paddingRight = {[0, '1rem']} 
                    width = {['100%', '60%']} 
                    position = 'relative'
                    marginTop = {[0, '2.5rem']}
                    marginLeft = {[0, '2.5rem']}
                    marginBottom = {[0, '2.5rem']}
                    bg = '#000'>{
                        <ReactPlayer id='dupa' 
                            style = {{top:0,left:0,bottom:0,right:0, position: 'absolute'}}
                            url = {data['trialer']} 
                            width = '100%'
                    height = '100%'/>}
                </Box>
            </Box>
        </Box>
        
    );
}

export default Game